<section class="main-section">
    <div class="back-arrow">
        <button mat-icon-button (click)="navigateBack()">
            <mat-icon>arrow_back</mat-icon>
          </button>
    </div>
    <div class="blog-details">
        <h1 class="blog-title">{{blogDetail.postTitle}}</h1>
        <ng-container *ngFor="let item of blogDetail?.blocks">
            <div *ngIf="item.type=='header'" >
                <h1 *ngIf="item.data.level==1" [innerHTML]="item.data.text"></h1>
                <h2 *ngIf="item.data.level==2" [innerHTML]="item.data.text"></h2>
                <h3 *ngIf="item.data.level==3" [innerHTML]="item.data.text"></h3>
                <h4 *ngIf="item.data.level==4" [innerHTML]="item.data.text"></h4>
                <h5 *ngIf="item.data.level==5" [innerHTML]="item.data.text"></h5>
                <h6 *ngIf="item.data.level==6" [innerHTML]="item.data.text"></h6>
                </div>
                <div *ngIf="item.type=='paragraph'" >
                    <p class="inner-text" [innerHTML]="item.data.text"></p>
                </div>
                <div *ngIf="item.type=='list'" >
                    <!-- <div *ngIf="item.data.style=='unordered'">
                        <ng-container *ngFor="let list of item.data.items">
                            <ul>
                                <li>{{ list }}</li>
                            </ul> 
                        </ng-container>   
                    </div> -->
                    <div *ngIf="item.type=='list'">
                        <div *ngIf="item.data.style=='ordered'">
                          <ol>
                            <ng-container *ngFor="let list of item.data.items">
                              <li>{{ list }}</li>
                            </ng-container>
                          </ol>
                        </div>
                      </div>
                </div>
                <div *ngIf="item.type=='delimiter'" class="ce-delimiter">
                </div>
                <div *ngIf="item.type=='image'" class="image-container">
                        <img [src]="item.data.file.url" alt="">
                </div>
                <div *ngIf="item.type=='table'">
                    
                    <table>
                        <ng-container *ngFor="let data of item.data.content">
                            <tr>
                                <ng-container *ngFor="let item of data">
                                <td>{{ item }}</td>
                                </ng-container> 
                            </tr>
                        </ng-container>       
                    </table>
                    
                </div> 
        </ng-container>
    </div>
</section>